import { FormGroup, Form, Input, Box, Button, Card, CardHeader, CardContent, Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Chip, CircularProgress, Divider, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, Tab, Tabs, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react"
import Layout from "../../components/shared-layout";
import Breadcrumb from "../../components/shared-breadcrumb";
import useStores from "../../hooks/use-stores"
import VmButton from "../../components/shared-button";
import VmModal from "../../components/shared-modal";
import VmTable from "../../components/shared-table";
import { navigate } from "gatsby";
import { GetCollegeId, isBrowser, ProdEnvCheck } from "../../constants/options";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { INLINE_SELECTED_OPTION, INLINE_SELECT_OPTION, TD_FIRST, TD_FIRST_PENDING_REQUEST, TD_LAST, TD_LAST_PENDING_REQUEST, TD_NORMAL, TD_NORMAL_PENDING_REQUEST } from "../../constants/style";
import TabPanel from "../../components/shared-tab-panel";
import SlideDown from "react-slidedown";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HistoryIcon from '@mui/icons-material/History';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import TopicIcon from '@mui/icons-material/Topic';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import PendingIcon from '@mui/icons-material/Pending';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import VmVisitSlideDownCard from "../../components/shared-VisitSlideDownCard";
import TraineeshipCard from "../../components/shared-traineeship-card";
import CollapsedSection from "../../components/shared-collapsed-section";
import { isWeb } from "../../utilities/platform";
import RefreshIcon from '@mui/icons-material/Refresh';



const StudentProfilePage = observer(() => {
  const { userStore, rootStore } = useStores();
  const [profile, setProfile] = useState<any>();
  const [tabValue, setTabValue] = useState<number>(1);
  const [currentHrCode, setCurrentHrCode] = useState<number>(0);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [birthDate, setBirthDate] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [cPassword, setCPassword] = useState<string>("");
  const [usi, setUsi] = useState<string>("");
  const encryptedPassword = "*ENCRYPTED*";
  const userInfo = isBrowser && localStorage.USER_INFO ? JSON.parse(localStorage.USER_INFO) : {};
  const [confirmButton, setConfirmButton] = useState<boolean>(false)

  // VISIT
  // visit tab: fold / unfold
  const [fold, setFold] = useState<number>(0);
  const [visitTab, setVisitTab] = useState<number>(0);

  // create visit request
  const [createVisitModal, setCreateVisitModal] = useState<boolean>(false); // modal display
  const [currentCreateVisit, setCurrentCreateVisit] = useState<any>(); // data store
  const [visitFinalStartTime, setVisitFinalStartTime] = useState<Date>(new Date());

  // create visit request prop
  const [createVisitProp, setCreateVisitProp] = useState({
    visitId: 0,
    startTime: null,
    endTime: null,
    locationId: null
  })

  // check time availability 
  const [checkTimeAvailability, setCheckTimeAvailability] = useState<any>({
    startTime: "",
    endTime: "",
    trainerId: 0,
    traineeId: userInfo.Id,
    visitId: 0,
    traineeshipId: 0
  });

  const [checkTimeResponse, setCheckTimeResponse] = useState<any>([]);
  const [showNeedRequestList, setShowNeedRequestList] = useState<boolean>(false);
  const visitRef = useRef(null);
  useEffect(() => {
    setCheckTimeAvailability({
      ...checkTimeAvailability,
      startTime: createVisitProp.startTime,
      endTime: createVisitProp.endTime
    });
    console.log(checkTimeAvailability);
  }, [createVisitProp]);



  // current trainee
  const [currentTraineeShip, setCurrentTraineeShip] = useState<any>();
  const [traineeFold, setTraineeFold] = useState<number>(0);

  useEffect(() => {
    onRequestStudentProfile();
  }, []);


  const onRequestStudentProfile = () => {
    userStore.getStudentProfile()
      .then((res) => {
        setProfile(res);
        setOldPassword(res.password);
        onRefreshFields(res);
        //   if (res.studentSemesters.length > 0)
        //     setCurrentHrCode(res.studentSemesters[0].id)
      });
  }

  const onRefreshFields = (sourceData?: any) => {
    let res = sourceData ? sourceData : profile;
    setFirstName(res.firstName);
    setLastName(res.lastName);
    setUsername(res.username);
    setTitle(res.title);
    setMiddleName(res.middleName);
    setEmailAddress(res.emailAddress);
    setBirthDate(res.birthDate.split('T')[0]);
    setPassword(encryptedPassword);
    setCPassword(encryptedPassword);
    setUsi(res.usi);
  }

  const onSaveProfile = () => {
    if (password !== cPassword) {
      rootStore.notify(t('TWO_PASSWORDS_NOT_MATCH'), 'warning');
      return;
    }
    let req = {
      id: profile.id,
      username,
      title,
      firstName,
      lastName,
      middleName,
      emailAddress,
      password: password === encryptedPassword ? oldPassword : password,
      // studentSemesters: profile.studentSemesters,
      birthDate: birthDate + "T00:00:00",
      collegeId: GetCollegeId(),
    }
    console.log(req);
    userStore.updateStudentProfile(req)
      .then(() => {
        let userInfo = {
          title: req.title,
          firstName: req.firstName,
          lastName: req.lastName,
          email: req.emailAddress,
          collegeId: req.collegeId,
          birthDate: req.birthDate,
          usi: isWeb && localStorage.USER_INFO && JSON.parse(localStorage.USER_INFO).usi,
        }
        localStorage.USER_INFO = JSON.stringify(userInfo);
        setEditMode(false);
        const email = isWeb && localStorage.USER_INFO ? JSON.parse(localStorage.USER_INFO).email : '';
        if (req.emailAddress !== email) {
          rootStore.notify('You email address is changed! Please valid the new email before login!', 'info');
          userStore.signOut();
        } else {
          rootStore.notify(t('STUDENT_PROFILE') + t('UPDATED_B'), 'success');
        }
        localStorage.USER_INFO = JSON.stringify(userInfo);
        onRequestStudentProfile();
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'))
  }

  // VISIT
  const handleCloseCreateVisitModal = () => {
    setCreateVisitModal(false)
    setCreateVisitProp({
      visitId: 0,
      startTime: null,
      endTime: null,
      locationId: null
    })
    setCheckTimeResponse([]);
    setConfirmButton(false);
  }

  const handleCreateVisitModal = (id: number) => {
    let target = userStore.traineeshipVisitList.data.find(v => v.id == id);
    console.log("visitmodaltarget: ", target)
    setCurrentCreateVisit(target);
    setCreateVisitProp({ ...createVisitProp, "visitId": target.id, "startTime": target.startTime, "endTime": target.endTime, "locationId": target.locationId })
    setCreateVisitModal(true);
    userStore.getOrganisationListbyGrouId(target.location.groupId)
  }

  const createVisitPostHandler = () => {
    const date = new Date(Date.now());
    const day: number = visitFinalStartTime.getDate() - date.getDate();
    const hour: number = ((day * 24) + visitFinalStartTime.getHours()) - date.getHours();
    const minute: number = ((hour * 60) + visitFinalStartTime.getMinutes() - date.getMinutes());
    console.log(minute);
    handleCloseCreateVisitModal();
    userStore.checkTimeAvailability(checkTimeAvailability).then(() => {
      if (userStore.visitOverlapList.length !== 0) {
        setCheckTimeResponse(userStore.visitOverlapList)
      } else {
        if (minute > 1440) {
          setCreateVisitModal(false);
          userStore.setVisitTableLoading(true)
          userStore.createVisitChangeRequest(createVisitProp).then(() => {
            rootStore.notify('New visit time requested!', 'success');
            userStore.getChangeRequestListByVisitId(createVisitProp.visitId);
            setVisitTab(0);
          })
          //window.location.reload()
        } else {
          rootStore.notify('If you need to change your visit with less than 24 hours notice, contact Head Office directly on 1800 286 916.', 'error');
        }
      }
    })
  }



  const createVisitPropHandler = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setCreateVisitProp({ ...createVisitProp, [name]: value })
    console.log(createVisitProp)
  }

  const onToggleFold = (id: number) => {
    if (fold === id) {
      setFold(0)
    } else {
      var delayInMilliseconds = 500; //1 second
      userStore.setVisitTableLoading(true);
      setTimeout(function () {
        userStore.getChangeRequestListByVisitId(id);
      }, delayInMilliseconds);
      //visitStore.getChangeRequestListByVisitId(id);
      setFold(id)
      // onToggleChangeVisitList(id)
    }
  }

  const onToggleTraineeshipFold = (id: number) => {
    if (traineeFold === id) {
      setTraineeFold(0);
    } else {
      setTraineeFold(id);
    }
  };

  const statusPostHandler = (visitId: number, changeId: number, status: boolean) => {
    const res = confirm(`Do you want to ${status === true ? "Accept the request?" : "Reject the request?"}?`)
    if (res) {
      userStore.updateChangeRequestStatus(changeId, status).then(() => {
        //studentStore.getVisitListByTraineeshipId(currentTraineeShip)
        let thisVisit = userStore.visitChangeList.find(v => v.id === changeId)
        console.log(`visit accept status , status: ${status}, orgAccept: ${thisVisit.orgAccept}, trainerAccept: ${thisVisit.trainerAccept}, traineeAccept: ${thisVisit.traineeAccept}`)
        if (status === true && thisVisit.orgAccept === true && thisVisit.trainerAccept === true) {
          userStore.getVisitListByTraineeshipId(currentTraineeShip.id)
          userStore.getChangeRequestListByVisitId(visitId)
        } else {
          userStore.getChangeRequestListByVisitId(visitId)
        }
      })
      alert("Confirmed")

    }
  }

  return (
    <ContentLayout
      pageName={t('MY_PROFILE')}
      pageHeading={t('MY_PROFILE')}
      breadCrumb={[
        { label: t('STUDENT_PROFILE'), to: "" },
        { label: t('MY_PROFILE'), to: "/student" }
      ]}
      buttonLabel={editMode ? t('CANCEL_EDIT') : t('EDIT_PROFILE')}
      onClickAction={() => {
        onRefreshFields();
        setEditMode(!editMode);
      }}
    >
      {userStore.loading || !profile ?
        <>
          <div className="text-center my-4"><Skeleton /></div>
          <div className="text-center my-4"><Skeleton /></div>
          <div className="text-center my-4"><Skeleton /></div>
          <div className="text-center my-4"><Skeleton /></div>
          <div className="text-center my-4"><Skeleton /></div>
          <div className="text-center my-4"><Skeleton /></div>
        </>
        :
        <>
          <CollapsedSection sectTitle={t('STUDENT_PROFILE')}>
            <div className="bg-white p-4 py-6 rounded-lg">
              <div className="grid grid-cols-4 gap-4">
                <TextField
                  value={username}
                  disabled
                  label={t('USERNAME')}
                />
                <TextField
                  value={title}
                  disabled={!editMode || (isWeb && localStorage.USER_INFO && JSON.parse(localStorage.USER_INFO).usi)}
                  onChange={(event) => setTitle(event.target.value)}
                  label={t('TITLE')}
                />
                <Box className="col-span-2" />
                <TextField
                  value={firstName}
                  disabled={!editMode || (isWeb && localStorage.USER_INFO && JSON.parse(localStorage.USER_INFO).usi)}
                  onChange={(event) => setFirstName(event.target.value)}
                  label={t('FIRST_NAME')}
                />
                <TextField
                  value={middleName}
                  disabled={!editMode || (isWeb && localStorage.USER_INFO && JSON.parse(localStorage.USER_INFO).usi)}
                  onChange={(event) => setMiddleName(event.target.value)}
                  label={t('MIDDLE_NAME')}
                />
                <TextField
                  value={lastName}
                  disabled={!editMode || (isWeb && localStorage.USER_INFO && JSON.parse(localStorage.USER_INFO).usi)}
                  onChange={(event) => setLastName(event.target.value)}
                  label={t('LAST_NAME')}
                />
              </div>
              <div className="grid grid-cols-4 gap-4 mt-4">
                <TextField
                  value={emailAddress}
                  onChange={(event) => setEmailAddress(event.target.value)}
                  disabled
                  label={t('EMAIL')}
                />
                <TextField
                  value={birthDate}
                  label={t('BOD')}
                  type="date"
                  variant="outlined"
                  disabled={!editMode || (isWeb && localStorage.USER_INFO && JSON.parse(localStorage.USER_INFO).usi)}
                  onChange={(value) => setBirthDate(value.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  value={password}
                  type="password"
                  disabled={!editMode}
                  onChange={(event) => setPassword(event.target.value)}
                  label={t('PASSWORD')}
                />
                {editMode &&
                  <TextField
                    value={cPassword}
                    type="password"
                    disabled={!editMode}
                    onChange={(event) => setCPassword(event.target.value)}
                    label={t('CONFIRM_PASSWORD')}
                  />
                }
                <TextField
                  value={usi}
                  disabled
                  label={t('USI')}
                />
              </div>
              {editMode && <div className="text-center">
                <VmButton
                  className="bg-highlight text-white px-4 py-2 mt-4"
                  style={{ height: 'fit-content' }}
                  onClick={onSaveProfile}
                >
                  {t('SAVE_CHANGES')}
                </VmButton>
              </div>}
            </div>
          </CollapsedSection>
          <Box mb={4} />

          <Typography className="lg:text-lg 2xl:text-2xl" fontWeight={'bold'} marginBottom={2}
            sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
            {t('Traineeship List')}
          </Typography>
          <VmTable
            loading={""}
            page={0}
            thead={["ID", "Enrolled in Course", "Course", "Accessor", "Relevant Organisation", t('ACTION')]}
            tbody={
              userStore.studentProfile.traineeships ?
                userStore.studentProfile.traineeships.map((traineeship: any, index: number) => (
                  <>
                    <Box marginY={1} />
                    <tr key={index} style={{ cursor: "pointer" }}>
                      <td className={TD_FIRST} onClick={() => onToggleTraineeshipFold(traineeship.id)} >{index + 1}</td>
                      <td className={TD_NORMAL} onClick={() => onToggleTraineeshipFold(traineeship.id)}>{traineeship.qualification.code}</td>
                      <td className={TD_NORMAL} onClick={() => onToggleTraineeshipFold(traineeship.id)}>{traineeship.qualification.name}</td>
                      <td className={TD_NORMAL} onClick={() => onToggleTraineeshipFold(traineeship.id)}>{traineeship.trainer ? <>{traineeship.trainer.firstName} {traineeship.trainer.lastName}</> : <>No Trainer</>}</td>
                      <td className={TD_NORMAL} onClick={() => onToggleTraineeshipFold(traineeship.id)}>{traineeship.organisation.name}</td>
                      <td className={TD_LAST}>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setCurrentTraineeShip(traineeship);
                            setTabValue(1);
                            setFold(0);
                            setShowNeedRequestList(false);
                            userStore.getVisitListByTraineeshipId(traineeship.id)
                              .then((visitList: any) => {
                                if (visitRef.current) visitRef.current.scrollIntoView(({ behavior: 'smooth' }));
                                if (visitList.length > 0 && visitList.filter((v: any) => !v.isTimeLocationConfirmed).length > 0) {
                                  rootStore.notify(`${visitList.filter((v: any) => !v.isTimeLocationConfirmed).length} visits is requesting time change!`, 'warning');
                                  setShowNeedRequestList(true);
                                  setTabValue(0);
                                }
                              });
                            setVisitTab(traineeship.id);
                            traineeship.trainer && setCheckTimeAvailability({ ...checkTimeAvailability, trainerId: traineeship.trainer.id, traineeshipId: traineeship.id });

                          }}
                        >
                          {t('VISIT LIST')}
                        </Button>
                      </td>
                    </tr>
                    <TraineeshipCard traineeship={traineeship} fold={traineeFold} colSpan={6} />
                    <Box marginY={1} />
                  </>
                ))
                : <tr><td className="text-center py-4" colSpan={10}>{t('NO_RESULT_FOUND')}</td></tr>
            }
          />



          {/* Visit List Tab */}
          {userStore.traineeshipVisitList && currentTraineeShip &&
            <>
              <div className="flex justify-between my-4">
                <Typography variant="h5" fontWeight={'bold'} marginTop={1}
                  sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
                  {t('VISIT_LIST')}: {userStore.studentProfile && userStore.studentProfile.firstName + " " + userStore.studentProfile.lastName}
                </Typography>
                {userStore.traineeshipVisitList.data.filter((v: any) => !v.isTimeLocationConfirmed).length > 0 &&
                  <Button variant="outlined" color={showNeedRequestList ? 'info' : 'error'} onClick={() => {
                    if (showNeedRequestList) setTabValue(1);
                    else setTabValue(0);
                    setShowNeedRequestList(!showNeedRequestList);
                  }}>
                    {showNeedRequestList ? 'Show All Visits' : 'Show Visits Pending for Time Changes'}
                  </Button>}
              </div>

              <Box sx={ITEM_PERFECT_INLINED} ref={visitRef} className="mb-2 justify-between">
                <Box sx={ITEM_PERFECT_INLINED}>
                  <span className="bg-sky-400 text-sky-400 rounded-2xl text-white p-2 pt-1 mr-2" />
                  <Typography>Trainer & Assesor Not Check In Yet</Typography>
                  <span className="bg-orange-400 text-orange-400 rounded-2xl text-white p-2 pt-1 ml-4 mr-2" />
                  <Typography> Check In By Trainer & Assesor</Typography>
                  <span className="bg-green-400 text-green-400 rounded-2xl text-white p-2 pt-1 ml-4 mr-2" />
                  <Typography> Check Out By Trainer & Assesor</Typography>
                </Box>
                <Button variant="outlined" sx={{ ml: 1 }} onClick={() => {
                  userStore.getVisitListByTraineeshipId(currentTraineeShip.id);
                  setFold(0);
                }}>
                  <RefreshIcon />
                </Button>
              </Box>

              {/* Current List */}
              <TabPanel value={tabValue} index={0}>
                <VmTable
                  loading={userStore.loadingVisitList}
                  page={0}
                  thead={["ID", t('VISIT_NAME'), "Date", t('START_TIME'), t('END_TIME'), "Check In Time", "Check Out Time", "File Upload Status", "Check in Status", t('ACTION')]}
                  // tbody={studentStore.visitCurrentList.data.length > 0 ?
                  //   studentStore.visitCurrentList.data.map((visit: any, index: number) => (
                  tbody={userStore.traineeshipVisitList.data.filter((v: any) => !v.isTimeLocationConfirmed).length > 0 ?
                    userStore.traineeshipVisitList.data.filter((v: any) => !v.isTimeLocationConfirmed).map((visit: any, index: number) => (
                      <>
                        <Box marginY={1} />
                        <tr key={`visit_${index}`} style={{ cursor: "pointer" }}>
                          <td className={visit.isTimeLocationConfirmed ? TD_FIRST : TD_FIRST_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{index + 1}</td>
                          <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.name}</td>
                          <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.finalStartTime.split('T')[0]}</td>
                          <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.finalStartTime.split('T')[1]}</td>
                          <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.finalEndTime.split('T')[1]}</td>
                          <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.checkInTime ? visit.checkInTime.split('T')[1] : "No Check In Time Yet"}</td>
                          <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.checkOutTime ? visit.checkOutTime.split('T')[1] : "No Check In Time Yet"}</td>
                          <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} title={visit.organisationVisitDocuments.length > 0 ? "Files Uploaded" : "No File Uploaded"} onClick={() => onToggleFold(visit.id)}>
                            {visit.organisationVisitDocuments.length > 0 ?
                              <Box sx={ITEM_PERFECT_INLINED} className="text-green-500 text-3xl pl-8" textAlign={"center"}><FileDownloadDoneIcon fontSize="inherit" /></Box>
                              : <Box sx={ITEM_PERFECT_INLINED} className="text-red-500 text-3xl pl-8" textAlign={"center"}><FileDownloadOffIcon /></Box>}
                          </td>
                          <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>
                            <span className={`${visit.checkInTime ? "bg-orange-400" : "bg-sky-400"} rounded-2xl text-white p-2 pt-1`}>
                              <Typography variant="caption">{visit.checkInTime ? t('Checked in') : t('Not Checked in yet')}</Typography>
                            </span>
                          </td>
                          <td className={visit.isTimeLocationConfirmed ? TD_LAST : TD_LAST_PENDING_REQUEST}>
                            {!visit.checkInTime ?
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  handleCreateVisitModal(visit.id);
                                  setVisitFinalStartTime(new Date(visit.finalStartTime));
                                  setCheckTimeAvailability({ ...checkTimeAvailability, visitId: visit.id });
                                }
                                }

                                style={{ backgroundColor: "white" }}
                              >
                                {t('RESCHEDULE')}
                              </Button>
                              :
                              <Button
                                variant="outlined"
                                disabled
                              >
                                {t('RESCHEDULE')}
                              </Button>
                            }
                          </td>
                        </tr>

                        <VmVisitSlideDownCard
                          visit={visit}
                          fold={fold}
                          loadingVisitTable={userStore.loadingVisitTable}
                          currentTraineeship={currentTraineeShip}
                          visitChangeList={userStore.visitChangeList}
                          colspan={10}
                        />
                        <Box marginY={1} />
                      </>
                    )) : <tr><td colSpan={10} className="text-center py-2">{t('NO_HISTORY_FOUND')}</td></tr>}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <VmTable
                  loading={userStore.loadingVisitList}
                  page={0}
                  thead={["ID", t('VISIT_NAME'), "Date", t('START_TIME'), t('END_TIME'), "Check In Time", "Check Out Time", "File Upload Status", "Check in Status", t('ACTION')]}
                  // tbody={studentStore.visitCurrentList.data.length > 0 ?
                  //   studentStore.visitCurrentList.data.map((visit: any, index: number) => (
                  tbody={userStore.traineeshipVisitList.data.length > 0 ?
                    userStore.traineeshipVisitList.data.map((visit: any, index: number) => (
                      <>
                        <Box marginY={1} />
                        <tr key={`visit_${index}`} style={{ cursor: "pointer" }}>
                          <td className={visit.isTimeLocationConfirmed ? TD_FIRST : TD_FIRST_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{index + 1}</td>
                          <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.name}</td>
                          <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.finalStartTime.split('T')[0]}</td>
                          <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.finalStartTime.split('T')[1]}</td>
                          <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.finalEndTime.split('T')[1]}</td>
                          <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.checkInTime ? visit.checkInTime.split('T')[1] : "No Check In Time Yet"}</td>
                          <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>{visit.checkOutTime ? visit.checkOutTime.split('T')[1] : "No Check In Time Yet"}</td>
                          <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} title={visit.organisationVisitDocuments.length > 0 ? "Files Uploaded" : "No File Uploaded"} onClick={() => onToggleFold(visit.id)}>
                            {visit.organisationVisitDocuments.length > 0 ?
                              <Box sx={ITEM_PERFECT_INLINED} className="text-green-500 text-3xl pl-8" textAlign={"center"}><FileDownloadDoneIcon fontSize="inherit" /></Box>
                              : <Box sx={ITEM_PERFECT_INLINED} className="text-red-500 text-3xl pl-8" textAlign={"center"}><FileDownloadOffIcon /></Box>}
                          </td>
                          <td className={visit.isTimeLocationConfirmed ? TD_NORMAL : TD_NORMAL_PENDING_REQUEST} onClick={() => onToggleFold(visit.id)}>
                            <span className={`${visit.checkInTime ? "bg-orange-400" : "bg-sky-400"} rounded-2xl text-white p-2 pt-1`}>
                              <Typography variant="caption">{visit.checkInTime ? t('Checked in') : t('Not Checked in yet')}</Typography>
                            </span>
                          </td>
                          <td className={visit.isTimeLocationConfirmed ? TD_LAST : TD_LAST_PENDING_REQUEST}>
                            {!visit.checkInTime ?
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  handleCreateVisitModal(visit.id);
                                  setVisitFinalStartTime(new Date(visit.finalStartTime));
                                  setCheckTimeAvailability({ ...checkTimeAvailability, visitId: visit.id });
                                }
                                }

                                style={{ backgroundColor: "white" }}
                              >
                                {t('RESCHEDULE')}
                              </Button>
                              :
                              <Button
                                variant="outlined"
                                disabled
                              >
                                {t('RESCHEDULE')}
                              </Button>
                            }
                          </td>
                        </tr>

                        <VmVisitSlideDownCard
                          visit={visit}
                          fold={fold}
                          loadingVisitTable={userStore.loadingVisitTable}
                          currentTraineeship={currentTraineeShip}
                          visitChangeList={userStore.visitChangeList}
                          colspan={10}
                        />
                        <Box marginY={1} />
                      </>
                    )) : <tr><td colSpan={10} className="text-center py-2">{t('NO_HISTORY_FOUND')}</td></tr>}
                />
              </TabPanel>
            </>}
        </>
      }

      {/* Create Visit Modal */}
      <VmModal
        openModal={createVisitModal}
        onClose={handleCloseCreateVisitModal}
        width={600}
        title={t('RESCHEDULE')}
        showButton={false}>
        {currentCreateVisit &&
          <>
            <form onSubmit={createVisitPostHandler}>
              <FormGroup>
                {/* <FormControl>
                  <InputLabel htmlFor="locationId">Location</InputLabel>
                  <Input style={{ marginBottom: "10px" }} placeholder={currentCreateVisit.locationId} onChange={createVisitPropHandler} value={createVisitProp.locationId} name={"locationId"}></Input>
                  <Select
                    id="locationId"
                    value={createVisitProp.locationId}
                    label="location"
                    onChange={createVisitPropHandler}
                    name={"locationId"}
                    style={{ marginBottom: "10px" }}
                  >
                    {userStore.organisationList.data.map((org: any) => (
                      <MenuItem value={org.id}>{org.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                <TextField style={{ marginBottom: "10px" }} id="startTime" label="Start Time" type="datetime-local" defaultValue={currentCreateVisit.finalStartTime} onChange={createVisitPropHandler} value={createVisitProp.startTime} name={"startTime"} />
                <TextField style={{ marginBottom: "10px" }} id="endTime" label="End Time" type="datetime-local" defaultValue={currentCreateVisit.finalEndTime} onChange={createVisitPropHandler} value={createVisitProp.endTime} name={"endTime"} />
                {
                  checkTimeResponse && checkTimeResponse.map((time: any) =>

                    <Box sx={{ margin: "5% 10%", textAlign: "center", backgroundColor: "#ed9d9d", borderRadius: "10px", border: "2px red solid", padding: "1rem" }}>
                      <Typography mb={2} mt={1}>&nbsp;Time Conflict</Typography>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={CHECK_TIME_RESPONSE}>Start Time</TableCell>
                            <TableCell sx={CHECK_TIME_RESPONSE}>End Time</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell sx={CHECK_TIME_RESPONSE}>{time.overlapVisitTime.startTime.split("T")[0]} {time.overlapVisitTime.startTime.split("T")[1]}</TableCell>
                            <TableCell sx={CHECK_TIME_RESPONSE}>{time.overlapVisitTime.endTime.split("T")[0]} {time.overlapVisitTime.endTime.split("T")[1]}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>


                  )
                }
                <Box sx={ITEM_PERFECT_INLINED} className="justify-end">
                  {confirmButton ? <>
                    <Button variant="outlined" disabled={createVisitProp.startTime == currentCreateVisit.finalStartTime && createVisitProp.endTime == currentCreateVisit.finalEndTime} onClick={() => createVisitPostHandler()}>Submit Confirm</Button>
                    <Button sx={{ ml: 2 }} variant="outlined" color={"error"} onClick={handleCloseCreateVisitModal}>Cancel</Button>
                  </> : <Button variant="outlined" disabled={createVisitProp.startTime == currentCreateVisit.finalStartTime && createVisitProp.endTime == currentCreateVisit.finalEndTime} onClick={() => setConfirmButton(true)}>Submit</Button>}
                </Box>
              </FormGroup>
            </form>
          </>
        }
      </VmModal>
    </ContentLayout >
  )
});

const ITEM_PERFECT_INLINED = { display: 'flex', alignItems: 'center', flexWrap: 'wrap' };
const CHECK_TIME_RESPONSE = {
  textAlign: "center",
  borderTop: "1px white solid",
  borderBottom: "none"
};

export default StudentProfilePage;
