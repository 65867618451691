import * as React from "react"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SlideDown from "react-slidedown";
import { Typography, Box } from "@mui/material";
import { ITEM_PERFECT_INLINED } from "../constants/style";

const CollapsedSection = ({ children, collapseInitial = false, sectTitle = "" }) => {
  const [collapse, setCollapse] = React.useState<boolean>(collapseInitial);

  return (
    <div className={`bg-white ${!collapse && 'py-2'} p-8 pt-2 rounded-xl`}>
      <button type="button" className="text-3xl w-full" onClick={() => setCollapse(!collapse)}>
        <Box sx={ITEM_PERFECT_INLINED} className={`justify-between ${collapse ? 'mb-6' : 'mb-4'} mt-4 w-full`}>
          <div><Typography variant="h5" fontWeight={'bold'} sx={{ height: 'fit-content' }}>{sectTitle}</Typography></div>
          <div className="text-3xl">{collapse ? <KeyboardArrowDownIcon color={'inherit'} fontSize="inherit" />
            : <KeyboardArrowUpIcon color={'inherit'} fontSize="inherit" />}</div>
        </Box>
      </button>

      <SlideDown closed={!collapse}>
        {children}
      </SlideDown>
    </div>
  )
}

export default CollapsedSection
